
import { integer, oneOf, string } from 'vue-types';

export default {
    props: {
        src: string(),
        media: string(),
        sizes: string(),
        format: oneOf(['webp', 'jpg']).def('webp'),
        quality: integer().def(80),
    },
    computed: {
        type() {
            switch (this.format) {
                case 'jpg':
                    return 'image/jpeg';
                default:
                    return `image/${this.format}`;
            }
        },
        sizeOptions() {
            return {
                sizes: this.sizes,
                modifiers: {
                    format: this.format,
                    quality: this.quality,
                },
            };
        },
        /**
         * computed sizes
         */
        cSizes() {
            return this.$img.getSizes(this.src, this.sizeOptions);
        },
    },
};
