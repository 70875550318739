import { render, staticRenderFns } from "./ModuleVisual.vue?vue&type=template&id=b9015e3a&scoped=true&"
import script from "./ModuleVisual.vue?vue&type=script&lang=js&"
export * from "./ModuleVisual.vue?vue&type=script&lang=js&"
import style0 from "./ModuleVisual.vue?vue&type=style&index=0&id=b9015e3a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9015e3a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseVideo: require('/workspace/components/atoms/BaseVideo.vue').default,BasePicture: require('/workspace/components/atoms/BasePicture.vue').default,ParallaxContents: require('/workspace/components/molecules/ParallaxContents.vue').default})
