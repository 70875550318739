
import { array, arrayOf, bool, integer, oneOf, shape, string } from 'vue-types';

export default {
    inheritAttrs: false,
    props: {
        originalFormat: oneOf(['jpg', 'png']).def('jpg'),
        focalPoint: array().def([0.5, 0.5]),
        cover: bool().def(false),
        contain: bool().def(false),
        src: string(),
        /**
         * When passing in sizes
         */
        sizes: string(),
        format: string().def('webp'),
        // add the possibility for multiple <source> elements, enabling art direction
        // e.g. differentiation between portrait and landscape images
        // note that nuxt-picture doesn't provide this (yet)
        sources: arrayOf(
            shape({
                media: string(),
                src: string(),
                sizes: string(),
                format: oneOf(['webp', 'jpg']).def('webp'),
                quality: integer().def(80),
            })
        ),
        lazy: bool().def(false),
    },
    computed: {
        classes() {
            return {
                cover: this.cover,
                contain: this.contain,
                'has-focal': this.hasFocal,
            };
        },
        style() {
            if (this.hasFocal && (this.cover || this.contain)) {
                return {
                    '--focal-x': `${this.focalX}%`,
                    '--focal-y': `${this.focalY}%`,
                };
            }
            return null;
        },
        /**
         * Computed sources: either the sources passed in explicitly
         * or generate an array based on type, src and sizes.
         */
        cSources() {
            return (
                this.sources || [
                    {
                        format: this.format,
                        src: this.src,
                        sizes: this.sizesToUse,
                    },
                ]
            );
        },
        formats() {
            if (this.format !== this.originalFormat) {
                return [this.format, this.originalFormat];
            }
            return [this.format];
        },
        focalX() {
            return this.focalPoint[0] * 100;
        },
        focalY() {
            return this.focalPoint[1] * 100;
        },
        hasFocal() {
            return !(this.focalX === 50 && this.focalY === 50);
        },
        /**
         * Get the screens config from nuxt-image and generate a sizes string for all screens.
         * Used as a fallback when no sizes are passed in explicitly.
         */
        allSizes() {
            let sizes = '';

            const screens = Object.entries(this.$img.options.screens);
            sizes = screens
                .reduce((acc, [key, width], index) => {
                    const value = `${key}:${width}px`;
                    return `${acc}${value} `;
                }, '')
                .trim();

            return sizes;
        },
        sizesToUse() {
            return this.sizes || this.allSizes;
        },
    },
};
