
import { array, bool } from 'vue-types';

import videoPlayer from '@/mixins/videoPlayer';
import responsiveVideo from '@/mixins/responsiveVideo';

export default {
    mixins: [videoPlayer, responsiveVideo],
    props: {
        autoplay: bool().def(false),
        cover: bool().def(false),
        sources: array(),
        // useResponsive: bool().
    },
    data() {
        return {};
    },
    computed: {
        classes() {
            return {
                cover: this.cover,
                playing: this.playing,
            };
        },
        cProps() {
            return {
                autoplay: this.autoplay,
                muted: this.autoplay ? true : this.$attrs.muted,
                playsinline: this.$attrs.playsinline,
                loop: this.$attrs.loop,
            };
        },
        mappedSources() {
            // map out 'media' attributes, because browsers don't support this on video sources.
            // instead the browser will just play the first <source> it is able to play
            return this.sources?.map(({ media, ...attrs }) => attrs);
        },
        cSources() {
            if (this.mediaQueryStrings?.length) {
                return this.matchingSources || this.mappedSources;
            }
            return this.mappedSources;
        },
    },
    methods: {
        togglePlayback() {
            if (this.$refs.video.paused) {
                this.play();
            } else {
                this.pause();
            }
        },
        play() {
            this.$refs.video.play();
        },
        pause() {
            this.$refs.video.pause();
        },
        seek(time, resumePlayback = true) {
            this.$refs.video.currentTime = time;

            if (resumePlayback && !this.playing) {
                this.play();
            }
        },

        // --- EVENT HANDLERS ---------------------
        onCanPlay() {
            // workaround for Chrome being nasty with muted autoplay
            // @see: https://stackoverflow.com/questions/47638344/muted-autoplay-in-chrome-still-not-working
            if (this.$attrs.muted) {
                this.$el.muted = true;
            }
            this.$emit('canPlay');
        },
        onLoadedMetaData() {
            this.duration = this.$refs.video?.duration;
        },
        onTimeUpdate(e) {
            this.currentTime = this.$refs.video?.currentTime;
        },
        // onPlay() {
        //   this.isPlaying = true;
        //   this.hasPlayed = true;

        //   this.$emit('play');
        // },
        // onPause() {
        //   this.isPlaying = false;

        //   this.$emit('pause');
        // },
    },
};
