
import { bool } from 'vue-types';

import hasScrollTriggers from '@/mixins/hasScrollTriggers';
import { visual } from '@/types/visual';

export default {
    mixins: [hasScrollTriggers],
    props: {
        visual: visual.isRequired,
        useReveal: bool().def(true),
        useParallax: bool().def(true),
    },
    data() {
        return {
            isActive: false,
            revealed: false,
        };
    },
    computed: {
        kind() {
            return this.visual?.kind || 'image';
        },
        isVideo() {
            return this.kind === 'video';
        },
    },
    watch: {
        // isActive(value) {
        //   if (value) {
        //     if (this.isVideo) {
        //       this.$refs.video.play();
        //     }
        //   } else if (this.isVideo) {
        //     this.$refs.video.pause();
        //   }
        // },
    },
    methods: {
        /**
         * Use scrolltriggers to translate each of the images for a parallax-ish effect.
         */
        setupScrollTriggers() {
            this.setupActivation();
            this.setupReveal();
        },
        setupActivation() {
            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$el,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        this.isActive = true;
                    },
                    onLeave: () => {
                        this.isActive = false;
                    },
                    onEnterBack: () => {
                        this.isActive = true;
                    },
                    onLeaveBack: () => {
                        this.isActive = false;
                    },
                },
            });

            this.addScrollTrigger(tl);
        },
        setupReveal() {
            if (!this.useReveal) {
                return;
            }

            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$el,
                    start: 'top bottom-=100',
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        this.revealed = true;
                    },
                },
            });

            this.addScrollTrigger(tl);
        },
    },
};
