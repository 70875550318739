export default {
  mediaQueryList: [],
  data() {
    return {
      matchingQueries: [],
    };
  },
  computed: {
    mediaQueryStrings() {
      return this.sources?.map(({ media }) => media);
    },
    matchingSources() {
      return this.sources?.filter((source) => {
        return this.matchingQueries.includes(source.media);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setupQueries();
    });
  },
  methods: {
    setupQueries() {
      if (!this.mediaQueryStrings?.length) {
        return;
      }

      this.mediaQueryStrings.forEach((mediaQueryString) => {
        this.addQuery(mediaQueryString);
      });

      this.testQueries();
    },
    addQuery(mediaQueryString) {
      const mql = window.matchMedia(mediaQueryString);
      mql.addEventListener('change', this.onQueryChange);

      this.$options.mediaQueryList.push(mql);
    },
    onQueryChange(e) {
      this.testQueries();
    },
    /**
     * Test all mediaqueries.
     * Each matching one will be added to matchlist.
     */
    testQueries() {
      const matchingQueries = [];
      this.$options.mediaQueryList.forEach((mql) => {
        if (mql.matches) {
          matchingQueries.push(mql.media);
        }
      });
      this.matchingQueries = matchingQueries;
    },
  },
};
