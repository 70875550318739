import { render, staticRenderFns } from "./BasePicture.vue?vue&type=template&id=f649649e&scoped=true&"
import script from "./BasePicture.vue?vue&type=script&lang=js&"
export * from "./BasePicture.vue?vue&type=script&lang=js&"
import style0 from "./BasePicture.vue?vue&type=style&index=0&id=f649649e&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f649649e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureSource: require('/workspace/components/atoms/PictureSource.vue').default})
