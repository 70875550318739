export default {
  data() {
    return {
      player: null,
      playing: false,
      currentTime: 0,
      duration: 0,
    };
  },
  computed: {
    progress() {
      if (!this.duration) {
        return 0;
      }

      return this.currentTime / this.duration;
    },
  },
  watch: {
    duration(value) {
      this.$emit('duration', value);
    },
    /**
     * When currentTime is updated, emit an event, but only if a listener was attached.
     */
    currentTime(value) {
      if (this.$listeners?.timeupdate) {
        this.$emit('timeupdate', value);
      }
    },
  },
  methods: {
    play() {
      this.player?.play();
    },
    pause() {
      this.player?.pause();
    },
    togglePlayback() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },

    // --- PLAYER EVENTS ------------------------------------------------------
    onPlay() {
      this.playing = true;
      this.$emit('play');
    },
    onPause() {
      this.playing = false;
      this.$emit('pause');
    },
    onEnd(e) {
      this.playing = false;
      // console.log('onEnd', e);
    },
    onError(e) {
      // console.warn('onError', e);
    },
  },
};
