
import { Power0 } from 'gsap';

import { bool, number } from 'vue-types';
import hasScrollTriggers from '@/mixins/hasScrollTriggers';

export default {
    mixins: [hasScrollTriggers],
    props: {
        percentage: number().def(10),
        disabled: bool().def(false),
    },
    computed: {
        contentStyle() {
            if (this.disabled) {
                return null;
            }

            const scale = 1 + 2 / this.percentage;
            return {
                '--tw-scale-x': scale,
                '--tw-scale-y': scale,
            };
        },
    },
    methods: {
        /**
         * Use gsap ScrollTrigger to translate the contentfor a parallax-ish effect.
         */
        setupScrollTriggers() {
            if (this.disabled) {
                return;
            }

            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$el,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
                defaults: {
                    ease: Power0.easeNone,
                },
            });

            tl.fromTo(
                this.$refs.content,
                {
                    yPercent: -this.percentage,
                },
                {
                    yPercent: this.percentage,
                    ease: Power0.easeNone,
                }
            );

            this.addScrollTrigger(tl);
        },
    },
};
