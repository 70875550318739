import { shape, number, arrayOf } from 'vue-types';

import { assetShape } from '@/types/asset';

export const image = shape({
  ...assetShape,
  focalPoint: arrayOf(number()),
}).loose;

export const images = arrayOf(image);
