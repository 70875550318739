import { string, oneOf, shape } from 'vue-types';

export const assetTypes = ['image', 'video'];

export const assetShape = {
  src: string(),
  kind: oneOf(assetTypes).def('image'),
  // mimeType: string(),
};

export const asset = shape(assetShape);
